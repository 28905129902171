<template>
  <div id="evaluation-area">
    <header-function 
      :canDownload="availableForDownload" 
      @action:download-bulk="download(null, 'bulk')"
    />

    <pompe-table 
      v-bind="config.table"
      :loading="loading"
      @action:check-single="onRowSelected"
      @action:check-all="selectAllRows"
      @fetch-data="getData"
    >
      <template #action="{ row }">
        <a 
          href="javascript:void(0)"
          class="anchor-link"
          style="font-size: 14px;"
          @click="download(row.id, 'single')"
        >
          Download
          <b-icon icon="download" scale="1" class="ml-1"/>
        </a>
      </template>
    </pompe-table>
  </div>
</template>

<script>
import HeaderFunction from './HeaderFunction.vue';

export default {
  name: 'EvaluationArea',
  components: {
    'header-function': HeaderFunction,
  },
  data() {
    const _this = this;

    return {
      config: {
        table: {
          checkAllAvailable: false,
          checkableRows: true,
          data: [],
          columns: [
            {
              key: "creationDate",
              label: "Date of Assessment",
              format: (date) => _this.formatDateNormal(date),
            },
            {
              key: "action",
              label: "Action",
            },
          ],
          pagination: {
            pageNumber: 0,
            pageSize: 5,
            totalRows: 5,
          },
        }
      },
      loading: false,
    };
  },
  computed: {
    availableForDownload() {
      const checks = this.config.table.data.map(value => value.checked);
      const totalSelectedData = checks.filter(Boolean).length;

      return (totalSelectedData === 2) || (totalSelectedData === 3);
    },
  },
  mounted() {
    const { page } = this.$route.query;
    this.config.table.pagination.pageNumber = page;
    this.getData({ 
        pagination: { page: Number(page) - 1, size: 50 },
      }, 'mounted'
    );
  },
  methods: {
    onRowSelected(index) {
      this.config.table.data[index].checked = 
        this.config.table.data[index].checked !== undefined
        ? !this.config.table.data[index].checked 
        : true;

      const checks = this.config.table.data.map(value => value.checked);
      const totalSelectedData = checks.filter(Boolean).length;
      
      if (totalSelectedData === 3) {
        this.config.table.data.forEach(el => {
          if (el.checked === false) el.showCheck = false;
        }) 
      } else {
        this.config.table.data.forEach(el => {
          if (!el.showCheck) el.showCheck = true;
        }) 
      }
    },
    selectAllRows(value) {
      this.config.table.data = this.config.table.data.map((el) => ({
        ...el,
        checked: value
      }));
    },
    getData(params, state = null) {
      this.loading = true;

      const { page, size } = params?.pagination;

      const payload = {
        criteria: {
          field: 'patientId',
          op: 'is',
          value: this.$route.params.id
        },
        sorting: [{
          field: 'creationDate',
          dir: 'DESC'
        }],
        paging: { page, size }
      }

      this.$requestService
        .post(
          this.$apiEndpoints.assessments + '/search',
          payload,
          response => this.handleGetDataSuccess(response, state),
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleGetDataSuccess(response, state) {
      this.config.table.data = response.data.content.map(el => ({
        ...el,
        checked: false,
        showCheck: true,
      }));

      this.config.table.pagination =  {
        ...response.data.pageable,
        totalRows: response.data.totalElements
      }

      if (state !== 'mounted') {
        this.$router.push({
          name: 'patientOverview',
          params: { tab: 'evaluationDownload' },
          query: { 
            page: response.data.pageable.pageNumber + 1,
          },
        }) 
      }
    },
    download(id, state) {
      let payload = null;

      if (state === 'bulk') {
        const checks = this.config.table.data.filter(value => value.checked);
        payload = {
          domainIds: [
            ...checks.map(el => el.id)
          ],
          type: 'ASSESSMENTS'
        }
      } else {
        payload = {
          domainIds: [id],
          type: 'ASSESSMENTS'
        }
      }

      this.$requestService
        .post(
          this.$apiEndpoints.downloadHelper,
          payload,
          (response) => this.handleDownloadSuccess(response, state),
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleDownloadSuccess(response, state) {
      const page = state === 'bulk' ? 'evaluation-pdf-template' : 'assessment-pdf-template';
      window.open(`${window.location.origin}/web/${page}?url=${response.headers.location}`);
    },
    getForPopulate(url) {
      this.$requestService
        .get(
          url,
          {},
          this.getOneAssessment,
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
  },  
};
</script>

<style lang="scss">
#evaluation-area {
  height: 60vh !important;
}
.customIDWidth {
  width: 50px;
}
</style>
