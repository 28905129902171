<template>
  <div id="header-function-evaluation">
    <!-- table header actions -->
    <div class="row">
      <div class="col-8 text-left">
        <!-- Button for download all -->
        <span id="tooltip-target-1">
          <button
            class="btn btn-sm btn-pompe-primary ml-2"
            :disabled="!canDownload"
            @click="$emit('action:download-bulk')"
          >
            Download Selected
            <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderFunctionEvaluation",
  props: {
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
</style>
