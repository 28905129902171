<template>
  <div id="overview">
    <div class="custom-container pl-2 pr-2" v-if="isFetchSuccess">
      <div class="row">
        <div class="col-3">
          <!-- (currently) last visit date -->
          <detail-text
            v-if="content.patientOverview.dateOfMedicalHistory"
            label="Last visit date"
            :text="
              formatDateNormal(content.patientOverview.dateOfMedicalHistory)
            "
          />

          <!-- date patient consent signed -->
          <detail-text
            v-if="content.patientOverview"
            label="Date patient consent signed"
            :text="
              formatDateNormal(content.patientOverview.datePatientConsentSigned)
            "
          />

          <!-- date patient data processing consent signed -->
          <detail-text
            v-if="content.patientOverview"
            label="Date patient data processing consent signed"
          >
            <template #content>
              {{ renderDateProcessingConsentSigned }}
            </template>
          </detail-text>

          <!-- health insurance -->
          <detail-text
            v-if="content.patientOverview"
            label="Health Insurance"
            :text="content.patientOverview.healthInsurance"
          />

          <!-- date of birth -->
          <detail-text
            v-if="content.patientOverview"
            label="Year of birth"
            :text="content.patientOverview.yearOfBirth.toString()"
          />

          <!-- Gender -->
          <detail-text 
            v-if="content.patientOverview"
            label="Gender" 
            :text="content.patientOverview.gender" 
          />

          <!-- Height -->
          <detail-text
            v-if="content.patientOverview"
            label="Height"
            :text="`${content.patientOverview.height || '-'}` + ' cm'"
          />

          <!-- Weight -->
          <detail-text
            v-if="content.patientOverview"
            label="Weight"
            :text="`${content.patientOverview.weight || '-'}` + ' kg'"
          />
        </div>
        <div class="col" v-if="isFetchSuccess">
          <perfect-scrollbar :options="{ suppressScrollX: true }">

            <!-- Collapsible Section -->
            <patient-diagnosis 
              v-if="content.patientOverview.patientDiagnosis && content.patientOverview.patientDiagnosis !== null"
              :data="content.patientOverview.patientDiagnosis"
            />
            <medical-history
              v-if="content.patientOverview.diseases && content.patientOverview.diseases !== null"
              :data="content.patientOverview.diseases"
            />
            <concomitant-therapy 
              v-if="content.patientOverview.medications && content.patientOverview.medications !== null"
              :data="content.patientOverview.medications"
            />
            <pompe-therapy :data="content.patientOverview.pompeTherapies" />
            <surgical-procedures
              v-if="content.patientOverview.surgicalProcedures && content.patientOverview.surgicalProcedures !== null"
              :data="content.patientOverview.surgicalProcedures"
            />

            <br />

            <visit-list 
              :user-data="userData"
              :config="config.table.visit"
              :data="visitData" 
              :can-manage-patient="canManagePatient"
            />
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Sub Component
import MedicalHistory from "./MedicalHistory/Index";
import PompeTherapy from "./PompeTherapy/Index";
import SurgicalProcedures from "./SurgicalProcedures/Index";
import ConcomitantTherapy from './ConcomitantTherapy/Index';
import PatientDiagnosis from './PatientDiagnosis/Index';

import VisitList from "./VisitList/Index";

import { mapState } from "vuex";

export default {
  name: 'PatientOverview',
  components: {
    MedicalHistory,
    PompeTherapy,
    SurgicalProcedures,
    VisitList,
    ConcomitantTherapy,
    PatientDiagnosis,
  },
  props: {
    canManagePatient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const _this = this;

    return {
      userData: {},
      config: {
        table: {
          visit: {
            columns: [
              {
                key: 'seq',
                label: 'Visit Number',
                width: '150px'
              },
              {
                key: 'creationDate',
                label: 'Visit Date',
                format: (val) => _this.formatDateNormal(val)
              },
              {
                key: 'action',
                label: 'Action',
                actions: [
                  {
                    key: 'editVisit',
                    label: 'Edit visit',
                    icon: 'pencil',
                    execute: (val) => _this.startEditVisit(val.id) 
                  },
                ]
              }
            ],
            showPagination: false,
          }
        }
      },
      medicationsOpen: false,
      pompeTherapyOpen: false,
      surgicalProceduresOpen: false,
      concomitantTherapyOpen: false,
      isFetchSuccess: true,
      visitData: [],
      patientRef: '',
    }
  },
  computed: {
    ...mapState({
      content: (state) => state.overviewPage.content,
      options: (state) => state.overviewPage.options,
    }),
    renderDateProcessingConsentSigned() {
      return (
        this.formatDateNormal(this.content.patientOverview.datePatientDataProcessingConsentSigned)
        || 'Consent not provided/revoked'
      );
    }
  },
  async mounted() {
    this.getVisit();
    this.getUser();
  },
  methods: {
    getVisit() {
      this.loading = true;

      const payload = {
        criteria: {
          op: "is",
          field: "patientId",
          value: this.$route.params.id,
        },
        paging: {
          page: 0,
          size: 100,
        },
        sorting: [
          {
            field: "creationDate",
            dir: "DESC",
          },
        ],
        projecting: {
          fields: ["id", "patientId", "creationDate", "seq"],
        },
      };

      this.$requestService.post(
        this.$apiEndpoints.visit + "/search",
        payload,
        this.handleGetVisitSuccess,
        this.handleGetVisitFailed
      );
    },
    handleGetVisitSuccess(response) {
      this.visitData = response.data.content;
    },
    getUser() {
      this.$requestService.get(
        this.$apiEndpoints.getCurrentUser,
        {},
        this.handleGetUserSuccess,
        this.handleGetUserFailed
      );
    },
    handleGetUserSuccess(response) {
      this.userData = {...response.data};
    },
  },
};
</script>
