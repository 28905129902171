<template>
  <div id="assessment-overview">
    <div class="custom-container">
      <h5>Secondary Endpoints</h5>
      <table class="table table-striped table-bordered table-sm mt-3">
        <tr>
          <th width="220">Assessment</th>
          <th v-for="(item, idx) in numberOfColumn" :key="idx">
            <a 
              :id="`tooltip-target-other-col-${item}`"
              href="javascript:void(0)" 
              class="anchor-link"
              @click="goToEditAssessment(item)"
            >
              <b-icon :icon="canManagePatient ? 'pencil' : 'eye'"></b-icon> {{ item }}
              <b-tooltip :target="`tooltip-target-other-col-${item}`" triggers="hover">
                {{ canManagePatient ? 'Edit this assessment' : 'View this assessment' }}
              </b-tooltip>
            </a>
          </th>
        </tr>
        <tr v-for="(item, idx) in firstColumnValues" :key="idx">
          <td>{{ item.text }}</td>
          <td v-for="(childItem, childIdx) in tableValues" :key="childIdx">
            {{ childItem[item.value] }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherEndpoints",
  props: {
    firstColumnValues: {
      type: Array,
      required: true,
      default: () => [],
    },
    numberOfColumn: {
      type: Number,
      required: true,
      default: 0,
    },
    tableValues: {
      type: Array,
      required: true,
      default: () => [],
    },
    canManagePatient: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
     goToEditAssessment(numberOfVisit) {
      const id = this.tableValues[numberOfVisit - 1].assessmentId;

      this.$requestService.post(
        this.$apiEndpoints.startProcessEditAssessment +
          `?businessKey=${this.$route.params.id}&refKey=${id}`,
        {},
        (response) =>
          this.handleAssessmentStartEditSuccess(response, this.$route.params.id)
      );
    },
  }
};
</script>
